<template>
	<section>
		<a id="ceo"></a>
		<div class="container-lg">
			<div class="container py-4">
				<div class="row">
					<div class="col-12 text-center">
						<h1 class="blue" v-html="$t('FOR INVESTOR')"></h1>
					</div>
				</div>
			</div>
		</div>
		<div class="container-lg bg-ceo">
			<div class="container py-4">
				<div class="row">
					<div class="col-md-10 offset-md-1 text-center">
						<h2 class="mb-4"><b v-html="$t('CEO’s Talk')"></b></h2>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-8">
						<div class="row percent-ceo">
							<div class="col-md-4 text-center">
								<div class="percentc">
									<div class="percent">
										<div class="text text-uppercase" v-html="$t('Saving more than')"></div>
										<div class="number">
											<ICountUp :delay="1000" :endVal="20000" />
										</div>
										<div class="text text-uppercase" v-html="$t('lives')"></div>
									</div>
								</div>
							</div>
							<div class="col-md-4 text-center ">
								<div class="percentc">
									<div class="percent">
										<div class="text text-uppercase" v-html="$t('Transforming up to')"></div>
										<div class="number">
											<ICountUp :delay="1000" :endVal="10" />
										</div>
										<div class="text text-uppercase" v-html="$t('healthcare business')"></div>
									</div>
								</div>
							</div>
							<div class="col-md-4 text-center">
								<div class="percentc">
									<div class="percent">
										<div class="text text-uppercase" v-html="$t('Integrating with over')"></div>
										<div class="number">
											<ICountUp :delay="1000" :endVal="10" />
										</div>
										<div class="text text-uppercase" v-html="$t('partners')"></div>
									</div>
								</div>
							</div>

							<div class="col-12">
								<p class="gold text-bold" v-html="$t('With a high security and privacy standard of the medical services')"></p>
								<p class="white" v-html="$t('ceo_talk_detail')"></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<a id="team"></a>
		<div class="container-lg bg-gray">
			<div class="container py-4">
				<div class="row">
					<div class="col-md-10 offset-md-1 text-center">
						<h2 class="blue"><b v-html="$t('Our Team')"></b></h2>
						<p><b v-html="$t('We Bring Different People Together To Make Different Things Happen')"></b></p>
						<p v-html="$t('out_team_detail')"></p>
					</div>

					<u-animate-container class="col-12 text-center my-5">
						<u-animate class="number-box" name="flipInY">
							<div class="image"><img src="@/assets/images/icon-ceo1.png" class="ir" /></div>
							<div class="title" v-html="$t('Dependability')"></div>
						</u-animate>
						<u-animate class="number-box" name="flipInY">
							<div class="image"><img src="@/assets/images/icon-ceo2.png" class="ir" /></div>
							<div class="title" v-html="$t('Open-Mindedness')"></div>
						</u-animate>
						<u-animate class="number-box" name="flipInY">
							<div class="image"><img src="@/assets/images/icon-ceo3.png" class="ir" /></div>
							<div class="title" v-html="$t('Creativity')"></div>
						</u-animate>
						<u-animate class="number-box" name="flipInY">
							<div class="image"><img src="@/assets/images/icon-ceo4.png" class="ir" /></div>
							<div class="title" v-html="$t('Teamwork')"></div>
						</u-animate>
						<u-animate class="number-box" name="flipInY">
							<div class="image"><img src="@/assets/images/icon-ceo5.png" class="ir" /></div>
							<div class="title" v-html="$t('Outgoing')"></div>
						</u-animate>
						<u-animate class="number-box" name="flipInY">
							<div class="image"><img src="@/assets/images/icon-ceo6.png" class="ir" /></div>
							<div class="title" v-html="$t('Result-Orientation')"></div>
						</u-animate>
						<u-animate class="number-box" name="flipInY">
							<div class="image"><img src="@/assets/images/icon-ceo7.png" class="ir" /></div>
							<div class="title" v-html="$t('Sentiment')"></div>
						</u-animate>
					</u-animate-container>
				</div>
			</div>
		</div>

		<a id="business"></a>
		<div class="container">
			<div class="row my-5 py-4">
				<u-animate-container class="col-md-6 px-3 text-center">
					<u-animate class="img-bd type2" name="fadeInUp"><img src="@/assets/images/img-ceo1.jpg" class="ir" /></u-animate>
				</u-animate-container>
				<div class="col-md-6  px-3 px-md-5">
					<h3 class="blue uppercase line-left"><b v-html="$t('Business Prospect & Expansion')"></b></h3>
					<p v-html="$t('Doctor A to Z has been gathering several medical hubs of expertise doctors, hospitals and virtual hospital systems, together with the innovative healthcare solutions and resources in order to enhance the accessibility of treatments in the healthcare industry')"></p>
					<p v-html="$t('Moreover, we aim to serve online medical tourist service towards CLMV tourism, helping connect international travelers to our expert doctor community conveniently. Also, aim to expand the healthcare business to CLMV countries with our customized solutions which suit the business most')"></p>
				</div>
			</div>

			<a id="traction"></a>
			<div class="row my-5 py-4">
				<div class="col-md-6 px-3 px-md-5 order-2 order-md-1">
					<h3 class="blue uppercase line-right"><b v-html="$t('Our Traction & Growth')"></b></h3>
					<p v-html="$t('The reasoning behind developing traction is to grow the business, reaching the company targets effectively. We focus not only acquiring new customers but retaining our repeated customers as well')"></p>
				</div>
				<u-animate-container class="col-md-6 px-3 text-center order-1 order-md-2">
					<u-animate class="img-bd type3" name="fadeInUp"><img src="@/assets/images/img-ceo2.jpg" class="ir" /></u-animate>
				</u-animate-container>
			</div>

			<a id="investor"></a>
			<div class="row my-5 py-4">
				<u-animate-container class="col-md-6 px-3 text-center">
					<u-animate class="img-bd type2" name="fadeInUp"><img src="@/assets/images/img-ceo3.jpg" class="ir" /></u-animate>
				</u-animate-container>
				<div class="col-md-6  px-3 px-md-5">
					<h3 class="blue uppercase line-left"><b v-html="$t('Our Investor')"></b></h3>
					<p v-html="$t('We are proud to have a long-term oriented investors that include many of the reputative experts leading in each specific industry')"></p>
				</div>
			</div>
		</div>

		<a id="request"></a>
		<div class="container-fluid bg-form8">
			<div class="container py-0 py-md-5">
				<div class="row">
					<div class="col-lg-8 offset-lg-2 py-5">
						<form class="apply" @submit.prevent="sendContact">
							<div class="row">
								<div class="col-12 text-center">
									<h2 class="gold"><b v-html="$t('Contact for more company information')"></b></h2>
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Name')"></label>
									<input type="text" :placeholder="$t('Name')" required v-model="model.name" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Surname')"></label>
									<input type="text" :placeholder="$t('Surname')" required v-model="model.surename" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('Phone')"></label>
									<input type="tel" :placeholder="$t('Phone')" required maxlength="10" v-model="model.phone" />
								</div>
								<div class="col-lg-6">
									<label v-html="$t('E-mail')"></label>
									<input type="email" :placeholder="$t('Email')" required v-model="model.email" />
								</div>
								<div class="col-12 text-center mt-3">
									<input type="submit" class="btn-submit solid" :value="$t('SEND')" />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ICountUp from 'vue-countup-v2';

export default {
	components: {
		ICountUp
	},
	data: () => ({
		model: {
			name: '',
			surename: '',
			phone: '',
			email: ''
		},
		modelEmail: {
			type: 'admin',
			subject: 'Contact for more company information : Doctor A to Z',
			from: '',
			to: process.env.VUE_APP_EMAIL_SERVICE,
			html: ''
		}
	}),
	methods: {
		async sendContact() {
			let html = ''
			html += '<div>Name: ' + this.model.name + ' ' + this.model.surename + '</div><br/>';
			html += '<div>Email: ' + this.model.email + ' </div><br/>';
			html += '<div>Phone: ' + this.model.phone + ' </div><br/>';
			html += '<div>Sincerly yours,</div><br/>';
			html += '<div>Doctor A to Z</div>';

			this.modelEmail.html = html;
			this.modelEmail.from = this.model.email;

			let urlEmail = "/email";
			await window.axios.post(urlEmail, this.modelEmail).then(async () => {
				alert(this.$t('Data has been sent successfully'));
				this.model = {
					name: '',
					surename: '',
					phone: '',
					email: ''
				}
			}).catch(error => {
				console.log(urlEmail, error)
			});
		}
	}
}
</script>